import $ from "jquery";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { SplitText } from "gsap/SplitText";

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin, SplitText);

window.jQuery = $;
window.$ = $;

const APP = (function () {
  $(document).ready(() => {
    init();
  });

  $(window).on("resize", () => {});

  function init() {
    console.log("🚀");

    scrollEffect();
    scrollToProject();
    markActiveMenu();
    mailMeButton();
    blenderColor();
  }

  function blenderColor() {
    const tl = gsap.timeline({ repeat: -1, yoyo: true });
    tl.to("#blender", {
      duration: 5,
      backgroundColor: "#dde810",
    });
    tl.to("#blender", {
      duration: 5,
      backgroundColor: "#e343c8",
    });
    tl.to("#blender", {
      duration: 5,
      backgroundColor: "#33578",
    });
    tl.to("#blender", {
      duration: 5,
      backgroundColor: "#234588",
    });
  }

  function scrollToProject() {
    const offset = $("#sidebar").css("padding-top").replace("px", "");
    $(".launch").on("click", (e) => {
      const target = "#" + $(e.target).attr("data-target");
      gsap.to("#content", {
        duration: 1,
        scrollTo: { y: target, offsetY: offset },
        ease: "power4.inOut",
      });
    });
  }

  function mailMeButton() {
    const tlGo = gsap.timeline({ paused: true });
    const tlCome = gsap.timeline({
      paused: true,
      onComplete: () => {
        $("#liam").attr("href", "mailto:hello@wideways.de");
      },
    });

    const splitText = new SplitText("#liam", { type: "chars,words" });
    const chars = splitText.chars; //an array of all the divs that wrap each character

    tlGo.to(chars, {
      duration: 0.3,
      opacity: 0,
      x: -5,
      stagger: {
        from: "end",
        each: 0.08,
      },
      onComplete: () => {
        $("#liam").append(
          "<span id='mailme' style='display: none;position: absolute; top: 0; left: 0;'>hello@wideways.de</span>"
        );
        const splitMail = new SplitText("#mailme", { type: "chars,words" });
        tlCome.to("#liam", {
          width: "12em",
          delay: 0.5,
          duration: 0.5,
        });
        tlCome.fromTo(
          splitMail.chars,
          {
            opacity: 0,
            x: -5,
          },
          {
            duration: 0.3,
            opacity: 1,
            x: 0,
            stagger: 0.06,
          }
        );
        gsap.set("#mailme", { display: "block" });
        tlCome.play();
      },
    });

    $("#liam").one("click", () => {
      tlGo.play();
    });
  }

  function markActiveMenu() {
    const triggers = gsap.utils.toArray(".project");

    triggers.forEach((trigger) => {
      const id = trigger.getAttribute("id");
      const menuItem = document.querySelector(`[data-target="${id}"]`);

      const textTL = gsap.timeline({ paused: true });

      textTL.to(menuItem, {
        duration: 0.3,
        fontWeight: 700,
        // fontSize: "+=2px",
        letterSpacing: "0.06em",
        opacity: 1,
        ease: "none",
      });

      ScrollTrigger.create({
        scroller: "#content",
        trigger: trigger,
        start: "top center",
        end: "bottom 48%",
        onEnter: () => {
          $(menuItem).addClass("active");
          textTL.play();
        },
        onLeaveBack: () => {
          $(menuItem).removeClass("active");
          gsap.delayedCall(0.2, () => {
            textTL.reverse();
          });
        },
        onLeave: () => {
          $(menuItem).removeClass("active");
          gsap.delayedCall(0.2, () => {
            textTL.reverse();
          });
        },
        onEnterBack: () => {
          $(menuItem).addClass("active");
          textTL.play();
        },
      });
    });
  }

  function scrollEffect() {
    console.log("🚀");
    // gsap.set(".scrollaffectme", {
    //   transformOrigin: "right " + self.direction === 1 ? "top" : "bottom",
    //   rotateX: 0,
    //   translateZ: -50,
    // });

    let proxy = { rotateX: 0, translateZ: 0 },
      skewSetter = gsap.quickSetter(".scrollaffectme", "rotateX", "deg"), // fast
      // scaleSetter = gsap.quickSetter(".scrollaffectme", "translateZ", "px"), // fast
      rotateXClamp = gsap.utils.clamp(-4, 0); // don't let the skew go beyond 20 degrees.
    // scaleClamp = gsap.utils.clamp(-400, 0); // don't let the skew go beyond 20 degrees.

    ScrollTrigger.create({
      scroller: "#content",
      onToggle: (self) => {
        if (!self.isActive) {
          gsap.to(".scrollaffectme", {
            rotateX: 0,
            translateZ: "0px",
            duration: 0.8,
            ease: "power3",
            overwrite: true,
            onUpdate: () => {
              skewSetter(proxy.rotateX);
              // scaleSetter(proxy.scale);
            },
          });
        }
      },
      onUpdate: (self) => {
        let velocity = Math.abs(self.getVelocity());
        let clapVelocity = gsap.utils.clamp(0, 20000, velocity);
        let scaledVelocity = gsap.utils.mapRange(0, 20000, 0, 1, clapVelocity);

        let circVelocity = Math.sqrt(1 - scaledVelocity * scaledVelocity) - 1;
        // let scale = scaleClamp(1 - velocity / 100);
        let rotateX = rotateXClamp(circVelocity) * self.direction;
        // console.log("VELO: " + velocity);
        // console.log("SVELO: " + scaledVelocity);
        // console.log("CIRC: " + circVelocity);

        gsap.set(".scrollaffectme", {
          transformOrigin:
            (self.direction == 1 ? "0%" : "100%") + " right 500px",
        });

        if (Math.abs(rotateX) > Math.abs(proxy.rotateX)) {
          proxy.rotateX = rotateX;
          // proxy.scale = scale;
          gsap.to(proxy, {
            rotateX: 0,
            // translateZ: "0px",
            duration: 0.5,
            ease: "power3",
            overwrite: true,
            onUpdate: () => {
              skewSetter(proxy.rotateX);
              // scaleSetter(proxy.scale);
            },
          });
        }
      },
    });

    // make the right edge "stick" to the scroll bar. force3D: true improves performance
    gsap.set(".scrollaffectme", {
      transformOrigin: "right top 500px",
      force3D: true,
    });
  }

  return {};
})();

window.APP = APP;
